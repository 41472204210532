a.skip-link {
  background-color: $white;
  border: 0.2rem solid $primary-color;
  display: block;
  font-size: 1.8rem;
  left: -1000em;
  padding: 0.8em;
  position: fixed;
  z-index: 10;

  &:focus,
  &:active {
    top: 1em;
    left: 1em;
    z-index: 1000000;
    color: $primary-color;
  }
}

.openMenu a.skip-link {
  display: none;
}
