.grid-layout .formulier-page-container-stepper-form {
  .download-block {
    background: $mijncak-light-blue;

    h2,
    h3 {
      font-size: 1.8rem;
      padding-bottom: 1.2rem;
      margin: 0;
    }
  }
}

#app:has(.formulier-page-container-stepper-form) {
  background-color: $cak-lighest-grey;
  min-height: 100vh;
  font-size: 1.6rem;
  font-family: $font-family;
  display: block;
  height: auto;
}

.formulier-page-container-stepper-form {
  font-family: $font-family-normal;
  font-size: 1.6rem;

  .tick-icon-container {
    background-color: $mijncak-dark-green;
  }

  .icon {
    &__currency {
      border-left: 0.1rem solid $mijncak-light-grey;
      border-top: 0.1rem solid $mijncak-light-grey;
      border-bottom: 0.1rem solid $mijncak-light-grey;
    }
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;

    input[type="checkbox"] {
      margin-right: 1rem;
    }
  }

  .formulier-page-container--opencak {
    .input__file,
    &,
    .klant-ondersteuning-form__buttons-container {
      .btn {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $cak-white;
        font-family: $font-family-bold;
        border-radius: 0px;
        padding: 0.8rem 1.1rem;
        line-height: 1;
        font-size: $font-size-buttontext;
        transition: background-color 0.4s, border-color 0.4s;
        display: inline-flex;
        align-items: center;
        min-width: auto;

        .icon:first-child {
          margin-right: 5px;
        }

        .icon::before {
          color: $cak-white;
        }
      }

      #volgendeButton:after,
      #vorigeButton:before {
        font-family: "icomoon-cak";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 1.4rem;
      }

      #volgendeButton {
        text-align: right;
        padding-right: 2rem;

        &:after {
          content: "\e950";
          padding-left: 1.2rem;
        }
      }

      #vorigeButton {
        text-align: left;
        padding-left: 2rem;

        &:before {
          content: "\e950";
          padding-left: 1.2rem;
          transform: scale(-1);
          display: inline-block;
        }
      }
    }
  }

  .formulier-page-container {
    .fieldset-container fieldset .input__amount {
      .icon + .form-control--amount {
        padding-left: 6.2rem;
      }
    }

    .input-group {
      position: relative;

      .form-control--amount {
        padding-left: 6.2rem;
      }

      .icon {
        &__currency--euro {
          background: $cak-darkest-blue;
          height: 5rem;
          width: 5rem;
          position: absolute;
          top: 0.1rem;
          display: block;
          left: 0.1rem;
          z-index: 9;
          pointer-events: none;

          &::before {
            content: "€";
            display: block;
            color: $cak-white;
            text-align: center;
            line-height: 5rem;
            width: 5rem;
          }
        }
      }
    }

    .form-control--is-valid-indicator {
      &::after {
        background: url("./images/tick-icon-green.svg") no-repeat center center;
        content: "";
        position: absolute;
        right: 0.1rem;
        top: 0.1rem;
        display: inline-block;
        height: 4.8rem;
        width: 4.8rem;
        padding: 0.5rem;
      }
    }

    .downshift-select {
      display: flex;
      border: none;
      min-height: 3.6rem;
      height: 100%;

      &::before {
        top: 1rem;
        border-color: $secondary-color;
      }

      &__input {
        border-left: 0.1rem solid $cak-grey;
        border-top: 0.1rem solid $cak-grey;
        border-bottom: 0.1rem solid $cak-grey;

        &:disabled {
          background-color: $cak-lighest-grey;
          border-color: $cak-lighest-grey;
        }
      }

      &__button {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 3.6rem;
        padding: 0.6rem 1.2rem;
        color: $cak-dark-grey;
        font-size: 1.4rem;
        border-right: 0.1rem solid $cak-grey;
        border-top: 0.1rem solid $cak-grey;
        border-bottom: 0.1rem solid $cak-grey;

        &:disabled {
          background-color: $cak-lighest-grey;
          border-color: $cak-lighest-grey;
        }
      }

      &__option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.4rem;
      }

      &__list {
        top: 3.6rem;
        width: 100%;
        right: 0;
        min-height: 12.2rem;
        max-height: 12.2rem;
        border: 0.1rem solid $cak-grey;
        background: $cak-white;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    p {
      font-size: 1.6rem;
    }
  }

  .input__file #choose-files {
    margin-left: 0;
    margin-right: auto;
    background: transparent;
    color: $primary-color-sub;
    padding: 0 0 0 2rem;
    border: 0;
    position: relative;

    &::before {
      content: "+";
      position: absolute;
      left: 0;
      top: -0.5rem;
      font-size: 2.4rem;
    }

    &:hover,
    &:focus {
      color: $primary-color !important;
      background-color: transparent !important;
    }
  }

  h2 {
    font-family: $font-family-bold;
    font-size: 2.3rem;
    line-height: 2.8rem;
    margin: 0;
  }

  .form-group {
    > div.flexdirection-column {
      margin-bottom: 0;
    }

    .alert.info-text.info-icon-outline {
      margin-top: 0.5rem;
      padding-left: 0;
    }
  }

  .formsection-container
    .fieldset-container__read-items
    .read_item.flexdirection-column {
    margin-top: 0;
  }

  .alert.info-text,
  .stepcounter-module + p {
    color: $mijncak-dark-grey;
    font-size: 1.4rem;
  }

  .alert.info-text {
    &::before {
      display: none;
    }
  }

  .stepcounter-module .stepcounter {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  // Don't show empty file input field
  .input__file .form-control {
    display: none;
  }

  .outroContainer {
    padding-bottom: 4rem;
    margin-top: 2.5rem;

    a {
      color: $primary-color;

      &:hover {
        color: $primary-color-sub;
      }
    }
  }

  .input__label-count-block {
    font-family: $font-family-normal;
    font-size: 1.1rem;
  }

  .klantondersteuning-form-page-container {
    padding: 2.5rem 0;
    min-height: unset;

    h1.form-title {
      padding-top: 3rem;
      margin-bottom: 0;
    }

    .read-item__value {
      font-size: 1.6rem;
    }

    .overview-edit-link {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      > a {
        background-color: $cak-white;
        color: $primary-color-sub;
        border: 0.1rem solid $primary-color-sub;
        display: block;
        height: 4rem;
        line-height: 4rem;
        text-decoration: none;
        padding: 0 1.5rem 0 4rem;
        font-family: $font-family-bold;
        transition: background-color 0.4s, border-color 0.4s;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0.9rem;
          left: 1.2rem;
          width: 1.9rem;
          height: 1.9rem;
          background: url("./images/pencil-icon.svg") no-repeat center center;
        }

        &:hover {
          background-color: $cak-lighest-grey;
        }
      }
    }

    form,
    .read-only-form {
      padding-top: 0;

      .read_item {
        padding-bottom: 2rem;
      }

      .read-item__label {
        font-family: $font-family-bold;
        font-size: 1.6rem;
      }
    }

    .formbutton-container {
      background: $cak-white;
      margin: 2.5rem 0 0;
      display: flex;
      justify-content: space-between;
      padding: 2.5rem;

      #vorigeButton,
      #volgendeButton {
        font-size: 1.6rem;
        line-height: 1.4rem;
        padding: 0.8rem 1.6rem;
        height: 4rem;

        &:before {
          margin-top: -0.3rem;
        }

        &:after {
          margin-top: -0.2rem;
        }

        &:after,
        &:before {
          font-size: 1.1rem;
        }
      }

      #vorigeButton {
        background-color: $cak-white;
        color: $primary-color-sub;
        border-color: $primary-color-sub;

        &:hover,
        &:focus {
          background-color: $cak-lighest-grey !important;
          color: $primary-color-sub !important;
          border-color: $primary-color-sub !important;
        }
      }
    }

    .form-control {
      border-color: $mijncak-lighter-grey;

      &:focus {
        border-color: $cak-darker-grey;
      }
    }

    .input__popup-hint {
      background: $cak-lighest-grey;
      font-family: $font-family-normal;
    }

    .input__popup-hint-arrow {
      bottom: -2rem;
      border-top-color: $cak-lighest-grey;
    }

    .form-group .alert.error-text {
      margin: 1rem 0 0;
    }

    .formsection-container {
      background-color: $cak-white;

      &__column {
        padding: 4rem 0;
      }

      & + .formsection-container {
        margin-top: 2.5rem;
      }

      .fieldset-container fieldset .form-group .downshift-select {
        height: 5rem;

        &::before {
          top: 1.7rem;
        }

        &__input {
          padding: 0.6rem 1.2rem;
        }

        &__list {
          top: 4.9rem;
        }
      }

      h2 {
        padding-left: 2.5rem;
        font-size: 2.3rem;
        line-height: 3rem;
        color: $primary-color;
      }

      .fieldset-container {
        background-color: $cak-white;
        padding: 1rem 2.5rem 0.5rem;
      }

      .form-group {
        .form-control {
          height: 5.2rem;

          &:not(.form-control--textarea) {
            padding-right: 5rem;
          }

          &:disabled {
            background-color: $cak-lighest-grey;
            border-color: $cak-lighest-grey;
          }
        }

        label:not(.radiobox-container, .checkbox-container),
        .radiogroup__label {
          font-size: 1.6rem;
          font-family: $font-family-bold;
          line-height: 2.1rem;
          padding-top: 0.6rem;
        }

        label.radiobox-container,
        label.checkbox-container {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }

        .input__file .file-list label {
          line-height: 3.5rem;
        }

        .file-list .file-row .btn.btn--transparent {
          background: transparent;
          border: none;
          color: #0f0f0f;
          margin-left: auto;
          margin-right: 1rem;
        }

        .read-only-form {
          padding-top: 0;

          .formbutton-container {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .icon-icon-info:before {
    color: $primary-color-sub;
    background-color: $cak-white;
  }

  .radio-box-group {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .radiobox-container {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  // Introduction page
  .form-step-introduction {
    .intro-text {
      & + .formsection-container {
        display: none;
      }
    }

    #vorigeButton {
      display: none;
    }

    .formbutton-container {
      justify-content: flex-end;
    }
  }

  // Nakijken en versturen
  .read-only-form.NakijkenEnVersturen {
    > .formsection-container {
      background-color: transparent;

      > .formsection-container__column {
        background: $cak-white;
      }
    }
  }

  .intro-text {
    background-color: $cak-white;
    padding: 4rem 2.5rem;

    br {
      display: none;
    }

    &.NakijkenEnVersturen {
      display: none;

      & + .formsection-container {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  // Result page
  .formulierpage-result {
    padding: 4rem 2.5rem;
    background-color: $cak-white;

    h2 {
      padding-bottom: 1.5rem;
    }

    h2,
    h3 {
      font-family: $font-family-bold;
    }
  }
}

@media (max-width: 768px) {
  .formulier-page-container-stepper-form,
  .formulier-page-container-stepper-form .formulier-page-container {
    margin-bottom: 0;
  }

  .formulier-page-container-stepper-form {
    .stepcounter-module .stepcounter > .step.step--active .counter-text,
    .stepcounter-module
      .stepcounter-final-step
      > .step.step--active
      .counter-text {
      background: transparent;

      .next-step-counter-text-mobile {
        display: block;
        font-family: $font-family;
      }
    }

    .klantondersteuning-form-page-container h1.form-title {
      padding-top: 0;
    }

    .stepcounter-module {
      padding-top: 1.5rem;
      padding-bottom: 2rem;

      .circular-progressbar-module {
        position: relative;

        .progressbar-background,
        .progressbar-bar {
          transition: stroke-dashoffset 1s linear;
          stroke-width: 0.6rem;
        }

        .progressbar-background {
          stroke: $toptask-border-color;
        }

        .progressbar-bar {
          stroke: $primary-color;
        }

        .circular-progressbar-text {
          position: absolute;
          top: 2rem;
          left: 1.3rem;
          font-size: 1.5rem;
        }
      }

      .step {
        display: none;
        background-color: transparent;

        .counter-step-mobile {
          display: block;
        }

        &.step--active {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          transform: none;

          .counter-text {
            align-items: flex-end;
            color: $primary-color;
            display: flex;
            flex-direction: column;
            position: static;
            transform: none;

            .counter-text__title {
              font-family: $font-family-bold;
              margin-bottom: 0.5rem;
              text-align: center;
            }
          }

          .counter-step-mobile {
            background-color: transparent;
            color: $black;
          }
        }

        .counter-step {
          display: none;
        }
      }
    }

    .stepcounter-module.stepcounter-module--all-visited {
      padding-bottom: 0;
    }

    .stepcounter-bar {
      display: none;
    }
  }
}

@media (min-width: 769px) {
  .formulier-page-container-stepper-form {
    .stepcounter-module .stepcounter > .step .counter-text,
    .stepcounter-module .stepcounter-final-step > .step .counter-text {
      background-color: transparent;

      .next-step-counter-text-mobile {
        display: none;
      }
    }

    .stepcounter-bar-context-mobile-finished {
      display: none;
    }

    .stepcounter-module {
      padding: 3rem 0 1.5rem;
      position: relative;

      .stepcounter {
        display: flex;
        justify-content: space-between;

        .stepcounter-bar {
          height: 0.1rem;
          background-color: $primary-color;
          opacity: 0.25;
          width: 100%;
          top: 7rem;
          position: absolute;
        }

        > .step {
          background-color: $cak-lighest-grey;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 6rem;
          padding-top: 2rem;
          position: relative;
          padding-right: 2rem;
          padding-left: 2rem;
          color: $black;

          &:nth-child(2) {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          .counter-text {
            transform: unset;
            left: unset;
            position: unset;
            height: unset;

            &__title {
              font-family: $font-family-normal;
              font-size: 1.4rem;
              line-height: 1.8rem;
              max-width: 12rem;
              margin: 0;
              transform: unset;
              left: unset;
              display: inline;
            }
          }

          .counter-step {
            height: 2.1rem;
            width: 2.1rem;
            font-size: 1.3rem;
            line-height: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $cak-white;
            color: $primary-color;
            border: 0.1rem solid $primary-color;
            left: auto;
            position: absolute;
            top: 0;
            transition: none;
            right: auto;
            border-radius: 50%;
          }

          .counter-step-mobile {
            display: none;
          }

          &.step--visited {
            .counter-step {
              background: $mijncak-dark-green;
              color: $cak-white;
              border-color: $mijncak-dark-green;
              text-indent: -9999rem;

              &::before {
                content: "";
                display: inline-block;
                transform: rotate(45deg);
                height: 1rem;
                width: 0.5rem;
                border-bottom: 0.2rem solid $cak-white;
                border-right: 0.2rem solid $cak-white;
                position: absolute;
                top: 0.3rem;
              }
            }
          }

          &.step--active {
            .counter-step {
              background: $primary-color;
              color: $cak-white;
              border-color: $primary-color;
              text-indent: 0;
              font-family: $font-family;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
