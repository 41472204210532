header {
  .header-nav-container {
    min-height: 16.2rem;
    background-color: $white;
    z-index: 1050;

    nav {
      flex-direction: row;
      padding: 0;

      @media only screen and (max-width: $mobileViewport) {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }

      .navbar-brand {
        position: absolute;
        top: 0;
        padding: 1.5rem 0;
        line-height: 2rem;
        height: 91px;

        @media only screen and (max-width: $mobileViewport) {
          position: static;
          padding-top: 0.7rem;
        }
      }

      .mobile-toggle-menu-container {
        @media only screen and (max-width: $mobileViewport) {
          padding-left: 1rem;
          flex-basis: calc(50% - 56px);

          .navbar-toggler {
            border: 0;
            position: relative;

            .icon-bar {
              background-color: $primary-color-sub;
              display: block;
              width: 22px;
              height: 2px;
              border-radius: 0.1rem;

              & + .icon-bar {
                margin-top: 0.4rem;
              }
            }

            .icon-text {
              position: absolute;
              top: 0;
              left: 4.1rem;
              line-height: 2rem;
              font-size: $font-size-menutext;
              height: 100%;
              color: $primary-color-sub;
              font-family: $font-family;
            }
          }
        }
      }

      .navbar-collapse {
        flex-direction: column-reverse;
        margin-top: 3rem;

        @media only screen and (max-width: $mobileViewport) {
          flex-direction: row;
          margin-top: 0;
          position: fixed;
          left: -100%;
          transition: left 0.3s ease;
          width: 100vw;

          &.show {
            background-color: $primary-color;
            width: 100%;
            bottom: 0;
            max-height: none !important;
            height: auto !important;
            top: 90px;
            z-index: 100000;
            left: 0%;
          }
        }

        .mobileclosebutton {
          display: none;

          @media only screen and (max-width: $mobileViewport) {
            background: transparent;
            border: 0 none transparent;
            margin: 0;
            padding: 0;
            display: block;
            margin-left: 2rem;
            margin-top: 1rem;
            width: 20px;
            height: 25px;
          }
        }

        // todo generieker, zie footer navbar (niet de .active)
        .navbar-nav {
          &.user-menu {
            .nav-link {
              &.active {
                border-bottom: 0;
              }

              &:last-child {
                margin-right: 0.5rem;

                @media only screen and (max-width: $mobileViewport) {
                  margin-right: 0;
                }
              }
            }

            .user-menu-icon,
            .user-menu-icon-mobile {
              width: 28px;
              height: 20px;
              margin-right: 0.5rem;
            }
            .user-menu-icon {
              display: inline;
            }

            .user-menu-icon-mobile {
              display: none;
            }

            @media only screen and (max-width: $mobileViewport) {
              .user-menu-icon {
                display: none;
              }

              .user-menu-icon-mobile {
                display: inline;
              }
            }
          }

          .nav-link {
            padding: 2rem 0;
            margin-left: 2rem;
            margin-right: 2rem;
            color: $primary-color;
            font-size: $font-size-menutext;
            font-family: $font-family-bold;

            &:first-child {
              margin-left: 0;
            }

            @media only screen and (max-width: $mobileViewport) {
              margin-left: 0;
              margin-right: 0;
              padding-left: 2rem;
              padding-right: 2rem;
              color: $white;
            }

            &.active {
              border-bottom: 0.4rem solid $secondary-color;
              padding-bottom: 1.8rem;
              border-bottom-color: $secondary-color;
              color: $secondary-color;

              @media only screen and (max-width: $mobileViewport) {
                border-bottom: 0.1rem solid $primary-color-sub;
                padding-bottom: 1.8rem;
                color: $white;
              }
            }
          }

          .menu-item-dropdown {
            color: $primary-color;
            font-size: $font-size-menutext;
            font-family: $font-family-bold;
            position: relative;

            @media only screen and (max-width: $mobileViewport) {
              margin-right: 0;
              padding-left: 5rem;
              border-bottom: 0.1rem solid $primary-color-sub;
            }

            button.nav-link {
              cursor: pointer;
              padding: 0;
              margin: 0;
              border: 0;
              position: relative;
              z-index: 1;
              background-color: transparent;

              &::before {
                content: "";
                position: absolute;
                left: -1.8rem;
                top: 0.2rem;
                border: solid $secondary-color;
                border-width: 0 0.2rem 0.2rem 0;
                display: inline-block;
                padding: 0.35rem;
                transform: rotate(-315deg);

                @media only screen and (max-width: $mobileViewport) {
                  border-color: $white;
                  left: -2rem;
                }
              }

              &--open {
                color: $secondary-color;
              }
            }

            .dropdown {
              position: absolute;
              top: 4rem;
              padding: 1rem 2rem;
              list-style: none;
              left: -2.2rem;
              min-width: 28rem;
              background: $white;
              display: none;

              @media only screen and (max-width: $mobileViewport) {
                position: relative;
                top: 0;
                left: 0;
                padding-left: 0;
                background: transparent;
                margin: 0;
              }

              &--open {
                display: block;
              }

              > li {
                a {
                  padding: 0.8rem 0;
                  display: block;

                  @media only screen and (max-width: $mobileViewport) {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.home-seperator {
  position: fixed;
  top: 16.2rem;
  z-index: 1040;

  @media only screen and (max-width: $mobileViewport) {
    display: none;
  }

  .line {
    height: 1px;
    background-color: #e7e7e7;
  }
}
