.icon-search-white:before {
  content: "\e95a";
}

.icon-CAK-cyaan-Wmo-consulenten-voorbereiden:before {
  content: "\e957";
}
.icon-CAK-cyaan-Subsidieregeling-Medische-Noodzakelijke-Zorg-Aan-Onverzekerden:before {
  content: "\e958";
}
.icon-CAK-cyaan-Aangeschreven-Broninhouder:before {
  content: "\e959";
}
.icon-spinner:before {
  content: "\e955";
}
.icon-trash:before {
  content: "\e956";
}
.icon-calendar:before {
  content: "\e952";
}
.icon-euro:before {
  content: "\e953";
}
.icon-upload:before {
  content: "\e954";
}
.icon-arrow-right:before {
  content: "\e950";
}
.icon-download:before {
  content: "\e94d";
}
.icon-info-closed-circle:before {
  content: "\e94e";
}
.icon-paperclip:before {
  content: "\e94f";
}
.icon-cak-kruis:before {
  content: "\e92b";
}
.icon-cak-personen:before {
  content: "\e92c";
}
.icon-cak-persoon:before {
  content: "\e938";
}
.icon-cak-spreekballon:before {
  content: "\e939";
}
.icon-cak-informatie:before {
  content: "\e951";
}
.icon-icon-info:before {
  content: "\e92a";
}
.icon-wlz-blauw:before {
  content: "\e900";
}
.icon-cak-pgb:before {
  content: "\e901";
}
.icon-cak-ik-ontvang-hulp-thuis:before {
  content: "\e902";
}
.icon-cak-blauw-zorg-buitenshuis:before {
  content: "\e903";
}
.icon-cak-blauw-medicijnenopreis:before {
  content: "\e904";
}
.icon-cak-jeugdwet-blauw:before {
  content: "\e905";
}
.icon-wmo-blauw:before {
  content: "\e906";
}
.icon-nationaal-contactpunt-magenta:before {
  content: "\e907";
}
.icon-nationaal-contactpunt-blauw:before {
  content: "\e908";
}
.icon-zin-magenta-wanbetalersregeling:before {
  content: "\e909";
}
.icon-zin-blauw-wanbetalersregeling:before {
  content: "\e90a";
}
.icon-zin-magenta-onverzekerdenregeling:before {
  content: "\e90b";
}
.icon-zin-blauw-onverzekerdenregeling:before {
  content: "\e90c";
}
.icon-zin-magenta-onverzekerbarevreemdelingen:before {
  content: "\e90d";
}
.icon-zin-blauw-onverzekerbare-vreemdelingen:before {
  content: "\e90e";
}
.icon-zin-magenta-gemoedsbezwaardenregeling:before {
  content: "\e90f";
}
.icon-zin-blauw-gemoedsbezwaardenregeling:before {
  content: "\e910";
}
.icon-zin-magenta-buitenlandregeling:before {
  content: "\e911";
}
.icon-zin-blauw-buitenlandregeling:before {
  content: "\e912";
}
.icon-plus-white:before {
  content: "\e913";
}
.icon-zakelijk-wmo:before {
  content: "\e914";
}
.icon-zakelijk-uitvalbestand-ontvangen:before {
  content: "\e915";
}
.icon-zakelijk-te-laat-met-aanleveren:before {
  content: "\e916";
}
.icon-zakelijk-pgb-2:before {
  content: "\e917";
}
.icon-zakelijk-parameters-wmo-aanpassen:before {
  content: "\e918";
}
.icon-zakelijk-nieuw-bij-cak:before {
  content: "\e919";
}
.icon-zakelijk-jeugdwet:before {
  content: "\e91a";
}
.icon-zakelijk-inlogproblemen:before {
  content: "\e91b";
}
.icon-zakelijk-gegevens-onjuist:before {
  content: "\e91c";
}
.icon-zakelijk-brief:before {
  content: "\e91d";
}
.icon-zakelijk-aanpassing-van-de-eigen-bijdrage:before {
  content: "\e91e";
}
.icon-slotje-white:before {
  content: "\e91f";
}
// .icon-gebruiker-white:before {
//   content: "\e920";
// }
// .icon-gebruiker-pink:before {
//   content: "\e921";
// }
.icon-slotje-pink:before {
  content: "\e922";
}
// .icon-gebruiker:before {
//   content: "\e923";
// }
.icon-slotje:before {
  content: "\e924";
}
.icon-email-blue:before {
  content: "\e925";
}
.icon-kruis:before {
  content: "\e926";
}
.icon-link-blue:before {
  content: "\e927";
}
.icon-link:before {
  content: "\e928";
}
.icon-locatie:before {
  content: "\e929";
}
.icon-nieuws_icoon:before {
  content: "\e92d";
}
.icon-nieuws-icon:before {
  content: "\e92e";
}
.icon-plus-black:before {
  content: "\e92f";
}
.icon-plus-blue:before {
  content: "\e930";
}
.icon-plus-green:before {
  content: "\e931";
}
.icon-plus-red:before {
  content: "\e932";
}
.icon-plus:before {
  content: "\e933";
}
.icon-potlood-blue:before {
  content: "\e934";
}
.icon-potlood:before {
  content: "\e935";
}
.icon-proefberekening:before {
  content: "\e936";
}
.icon-roteren:before {
  content: "\e937";
}
.icon-telefoon-blue:before {
  content: "\e93a";
}
.icon-telefoon:before {
  content: "\e93b";
}
.icon-tip:before {
  content: "\e93c";
}
.icon-toptaak-betaalgegevens:before {
  content: "\e93d";
}
.icon-toptaak-eigenbijdrage:before {
  content: "\e93e";
}
.icon-toptaak-medicijn-reisverklaring:before {
  content: "\e93f";
}
.icon-toptaak-mijnadreswijzigen:before {
  content: "\e940";
}
.icon-toptaak-mijnzorg:before {
  content: "\e941";
}
.icon-toptaak-overlijdendoorgeven:before {
  content: "\e942";
}
.icon-toptaak-vertegenwoordiger:before {
  content: "\e943";
}
.icon-twitter-blue:before {
  content: "\e944";
}
.icon-twitter:before {
  content: "\e945";
}
.icon-wolkje-blue:before {
  content: "\e946";
}
.icon-wolkje:before {
  content: "\e947";
}
.icon-circle-chevron-blue:before {
  content: "\e948";
}
.icon-datum_icoon:before {
  content: "\e949";
}
.icon-drank:before {
  content: "\e94a";
}
.icon-email:before {
  content: "\e94b";
}
.icon-externe_link:before {
  content: "\e94c";
}
