@media (min-width: 576px) {
  .container,
  .container-sm {
    // max-width: 540px;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    // max-width: 720px;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1170px;
  }
}
