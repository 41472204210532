/*
  Deze scss file definieert het Icomoon-cak font met benodigde uitzonderingen

  Er zijn een aantal specifieke iconen overgenomen uit font-awesome en glyphicons. Deze zijn toegevoegd aan icomoon-cak mbv de svg versies van specifieke iconen.
  De oorspronkelijke css classnames worden wel gebruikt, maar de fonts worden niet geinclude. Alleen icomoon-cak wordt geinclude.

  In de documentatie van het project is meer info te vinden over het process.
  
*/

@font-face {
  font-family: "icomoon-cak";
  src: url("./fonts/icomoon-cak/fonts/icomoon-cak.eot?svhrko");
  src: url("./fonts/icomoon-cak/fonts/icomoon-cak.eot?svhrko#iefix") format("embedded-opentype"),
    url("./fonts/icomoon-cak/fonts/icomoon-cak.ttf?svhrko") format("truetype"),
    url("./fonts/icomoon-cak/fonts/icomoon-cak.woff?svhrko") format("woff"),
    url("./fonts/icomoon-cak/fonts/icomoon-cak.svg?svhrko#icomoon-cak") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon-cak" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;

  color: #f0007a;
}

.cak-zakelijk {
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    color: #0095d5;
  }
}

@media only screen and (max-width: 991px) {
  [class^="icon-gebruiker"]:before,
  .cak-zakelijk [class^="icon-gebruiker"]:before {
    color: #fff;
  }
}

.cak-zakelijk .icon-icon-info:before,
.icon-icon-info:before {
  content: "\e92a";
  color: #233983;
}

.dropdown-menu .icon-link:before {
  color: #fff;
}

.glyphicon {
  font-family: "icomoon-cak" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-menu-right:before {
  content: "\e950";
}

.fa,
.icon.fa-euro,
.icon.fa-paperclip,
.icon.fa-info-circle,
.icon.fa-download,
.icon.fa-calendar,
.icon.fa-upload,
.icon.fa-trash,
.icon.fa-spinner {
  display: inline-block;
  font: normal normal normal 14px/1 "icomoon-cak";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-paperclip:before {
  content: "\e94f";
}

.fa-info-circle:before {
  content: "\e94e";
}

.fa-download:before {
  content: "\e94d";
}

.fa-calendar:before {
  content: "\e952";
}

.fa-upload:before {
  content: "\e954";
}

.fa-euro:before {
  content: "\e953";
}

.fa-spinner:before {
  content: "\e955";
}

.fa-trash:before {
  content: "\e956";
}

.fa-search:before {
  content: "\e95a";
}

//toegevoegde code
.btn .glyphicon {
  margin-left: 10px;
}

.btn .glyphicon:before {
  font-size: 14px;
}

@import "style-icomoon.scss";
