//browser default outline overwrite
* {
  outline: 0 !important;
}

.keyboard-focus,
.keyboard-focus:focus {
  outline: $keyboard-focus-outline-color solid !important;
  * {
    outline-width: 0 !important;
  }
}