$primary-color: #233983;
$primary-color-sub: #007eb5; // nieuwe kleur: #007EB5, oude kleur te weinig contrast knop: #0095d5
$primary-color-variant: #0066cc;
$primary-color-hover: #005ea1;
$primary-color-mobilesubitem-bg: #1658b6;

$secondary-color: #e70075; // nieuwe kleur: #E70075, oude kleur teweinig contrast knop: #F0007A
$secondary-color-hover: #aa126b;

$secondary-color-darker: #9d0a6d;
$secondary-color-darker-hover: #760952;

$cak-darkest-grey: #616161;
$cak-green: #00b500;
$cak-dark-green: #007500;
$cak-red: #ca0a22;
$cak-error-text-color: $cak-red;
$cak-grey: #dadada;

$white: #fff;

$message-top-color: $white;
$message-background-color: $primary-color;

$message-warning-color: $cak-red;

$generic-text-link-color: #0066cc;

$mijncak-paid: $cak-dark-green;
$mijncak-notpaid: $cak-red;
$mijncak-in-progress: $primary-color-sub;

$third-color: $cak-dark-green;

$clickable-elements-sub-color: $secondary-color;
$mijncak-icon-color: $secondary-color;

$faq-bg-color: #ccecf9;

$body-text: #1d1d1d;

$breadcrumbs-bg-color: #007eb5;

$block-bg-color: #f2f2f2;
$toptask-border-color: #c9c9c9;

$letop-boodschap-bg-color: #ffecf6;
$letop-boodschap-header-color: #d0021b;

$tip-boodschap-bg-color: #f0f4e1;
$tip-boodschap-header-color: #586f09;

$overlay-bgcolor: #d7d7d7;

$error-color: $cak-red;
$disabled-color: #d7d7d7;

//text
$font-family: "MarkPro-Light", sans-serif, arial;
$font-family-bold: "MarkPro-Bold", sans-serif, arial;
$font-size: 1.8rem;
$font-size-maintext: $font-size;
$font-size-menutext: $font-size;
$font-size-basetext: $font-size;
$font-size-small-text: 1.2rem;
$font-size-featuretext: 2rem;
$font-size-headertext: 3.2rem;

$font-size-home-calc-headertext: 2.8rem;
$font-size-home-level3-text: 2.4rem;
$font-size-head-level1-text: 3.2rem;
$font-size-head-level2-text: 2.8rem;
$font-size-head-level3-text: 2.6rem;

$font-size-faqhead-level2-text: 2.6rem;
$font-size-head-mijncak-block-level2-text: 2rem;
$font-size-breadcrumbs: 1.4rem;
$font-size-faq-widget-all: 1.2rem;
$font-size-home-newsdate: 1.4rem;

$font-size-faq-accordeon-title-text: 2.4rem;
$font-size-text-intro: 2.2rem;

$font-size-accordeon-header: 2rem;
$font-size-text-testimonial-quote: 2rem;
$font-size-news-h3: 1.4rem;

$font-size-situatie-regel-icon: 2.8rem;

$font-size-c2a-home-level1-text: 2.6rem;
$font-size-c2a-home-button-text: 1.8rem;
$font-size-toptaak-tekst: 1.6rem;
$font-size-vragen-headertext: 3.2rem;
$font-size-vragen-maintext: 1.8rem;
$font-size-vragen-showall: 1.2rem;
$font-size-calculator-header: 2.8rem;
$font-size-buttontext: 1.9rem;
$font-size-quote: 3.2rem;

$font-size-mijncak-boodschap-notificatie-titel: 2.2rem;
$font-size-mijncak-boodschap-notificatie-tekst: 1.8rem;

$zakelijk-secondary-color: #007eb5;
$zakelijk-secondary-color-hover: #005ea1;
$zakelijk-vragen-background: #007eb5;
$zakelijk-vragen-button-color: #233983;
$zakelijk-vragen-button-color-hover: #005ea1;
$zakelijk-link-icon-color: #007eb5;
$zakelijk-koptekst-algemeen-color: #005da0;
$zakelijk-colofon-koptekst-algemeen-color: #233983;
$servicemelding-bg-color: #007eb5;

$label-font-color: $cak-darkest-grey;

//shared/common - button
$button-padding: 0.8rem 1.1rem;
$button-margin: null;
$button-line-height: 1;
$button-border-width: null;
$button-min-width: null;
$button-font-size: 1.9rem;

$button-primary-background-color: $primary-color-sub;
$button-primary-border-color: $primary-color-sub;
$button-primary-text-color: $white;

$button-primary-hover-background-color: $primary-color-hover;
$button-primary-hover-border-color: $primary-color-hover;
$button-primary-hover-text-color: $white;

$button-secondary-background-color: null;
$button-secondary-border-color: null;
$button-secondary-text-color: null;

$button-secondary-hover-background-color: null;
$button-secondary-hover-border-color: null;
$button-secondary-hover-text-color: null;

$button-transparent-font-size: null;
$button-transparent-text-color: null;
$button-transparent-hover-text-color: null;

//shared/form - input
$form-control-input-padding: 0.6rem 1.2rem;
$form-control-input-background-color: $white;
$form-control-input-text-color: #555;
$form-control-input-margin: null;
$form-control-input-line-height: 1.42857143;
$form-control-input-border: 0.1rem solid #ccc;
$form-control-input-border-color: null;
$form-contorl-input-placeholder-color: null;
$form-control-input-border-focus-color: null;
$form-control-input-border-radius: null;
$form-control-input-size: 1.6rem;
$form-control-input-transition: null;
$form-control-input-width: 100%;
$form-control-input-height: null;
$form-control-input-box-shadow: null;
$form-control-input-placeholder-color: null;
$formcontrol-border-focus-color: null;

//backwards
$cak-dark-font-color: #0f0f0f;
$input-height: null; //todo rename
$cak-white: #fff;
$checkbox-checked-color: null;
$font-color: null;
// $font-size-headertext: null;
$cak-light-blue: #d7d7d7; //Spinner
$cak-blue: #233983; //Spinner
$cak-black: null;
$cak-very-light-grey: null;
$button-secondary-color: null; //hernoemen in button.scss

//border style
$border-style: solid;
$border-width: 0.1rem;
$border-color: $cak-very-light-grey;
$border: $border-style $border-width $border-color;
$border-radius: 0.5rem;

//sizes
$width-wrapper: 1139px;

// easings
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

// animations
@keyframes slideInFromRight {
  0% {
    right: -300px;
  }
  30% {
    right: -300px;
  }
  to {
    right: 0;
  }
}

@keyframes fadeIn {
  from {
    background: rgba($cak-dark-font-color, 0);
  }
  to {
    background: rgba($cak-dark-font-color, 0.5);
  }
}

@keyframes fadeInOpacity1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin external-links() {
  display: inline-block;
  font-family: "icomoon-cak" !important;
  font: normal normal normal 14px/1 icomoon-cak;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e94c";
  margin-right: 1rem;
  margin-left: 0;
  transition: margin-right 0.2s, margin-left 0.2s;
  -webkit-transition: margin-right 0.2s, margin-left 0.2s;
  -moz-transition: margin-right 0.2s, margin-left 0.2s;
  -ms-transition: margin-right 0.2s, margin-left 0.2s;
}

@mixin normal-link() {
  color: $generic-text-link-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

@mixin icon-in-front-of-link() {
  color: $primary-color;

  font-family: "icomoon-cak" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-right: 1rem;
  vertical-align: top;

  display: inline-block;
  text-decoration: none !important;

  font-size: 1.2em;
  line-height: 1;
  vertical-align: middle;
  margin-top: -0.5rem;

  transition: margin-right 0.2s, margin-left 0.2s;
  -webkit-transition: margin-right 0.2s, margin-left 0.2s;
  -moz-transition: margin-right 0.2s, margin-left 0.2s;
  -ms-transition: margin-right 0.2s, margin-left 0.2s;
}

@mixin icon-link-color() {
  color: $secondary-color;
}

@mixin icon-link-selectcharacter() {
  content: "\e928";
}

@mixin animation-hover-link-with-icon-infront() {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

@mixin reset-icon-link() {
  margin-right: 0;
  display: none;
  font-family: inherit;
  line-height: 1;
  content: "";
}

//vanuit mpr

// break text with...
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// z-indexes clear notifications
@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}
$z-indexes: ("modal", "navigation", "header", "content-overlay", "content", "footer");

// notification-bar height calculation
@mixin height-calculation($height) {
  $totalNotificationBars: 15;

  @for $i from 1 to $totalNotificationBars {
    &:nth-child(#{$i}) {
      bottom: (($height * $i) - $height) + px;
    }
  }
}

$cak-grey: #dadada;

$primary-color: #233983;
$primary-color-sub: #007eb5; // nieuwe kleur: #007EB5, oude kleur te weinig contrast knop: #0095d5
$primary-color-variant: #0066cc;
$primary-color-hover: #005ea1;
$primary-color-mobilesubitem-bg: #1658b6;

$secondary-color: #e70075; // nieuwe kleur: #E70075, oude kleur teweinig contrast knop: #F0007A
$secondary-color-hover: #aa126b;

$secondary-color-darker: #9d0a6d;
$secondary-color-darker-hover: #760952;

$white: #fff;
$black: #000;

$message-top-color: $white;
$message-background-color: $primary-color;

$message-warning-color: #ca0a23;

$generic-text-link-color: #0066cc;

$mijncak-paid: #007500;
$mijncak-notpaid: #ca0a22;

$third-color: #008232;
$cak-light-green: #86bc26;

$keyboard-focus-outline-color: #79bdfa;

$font-family: "MarkPro-Light", sans-serif, arial;
$font-family-bold: "MarkPro-Bold", sans-serif, arial;
$font-family-normal: "MarkPro", sans-serif, arial;

$clickable-elements-sub-color: $secondary-color;
$mijncak-icon-color: $secondary-color;

$faq-bg-color: #ccecf9;

$body-text: #1d1d1d;

$cak-white: #fff;
$cak-black-override: #000;
$cak-grey: #d7d7d7;
$cak-darker-grey: #969696;
$cak-dark-grey: #555555;
$cak-light-grey: #f3fcff;
$cak-lighest-grey: #f2f2f2;
$cak-darkest-blue: #024093;
$cak-light-green: #f0f4e1;
$panel-background-color: $cak-white;
$background-color: #f8f8f8;
$cak-red: #ab343e;
$cak-light-red: #fce9e9;

$breadcrumbs-bg-color: #007eb5;

$block-bg-color: #f2f2f2;
$toptask-border-color: #c9c9c9;

$letop-boodschap-bg-color: #ffecf6;
$letop-boodschap-header-color: #d0021b;

$tip-boodschap-bg-color: #f0f4e1;
$tip-boodschap-header-color: #586f09;

$overlay-bgcolor: #d7d7d7;

$error-color: #ca0a23;
$disabled-color: #d7d7d7;

$font-size-small-text: 1.2rem;
$font-size-maintext: 1.8rem;
$font-size-menutext: 1.8rem;
$font-size-featuretext: 2rem;

$font-size-basetext: 1.8rem;
$font-size-headertext: 3.2rem;

$font-size-home-calc-headertext: 2.8rem;

$font-size-home-level3-text: 2.4rem;

$font-size-head-level1-text: 3.2rem;
$font-size-head-level2-text: 2.8rem;
$font-size-head-level3-text: 2.6rem;

$font-size-faqhead-level2-text: 2.6rem;

$font-size-head-mijncak-block-level2-text: 2rem;

$font-size-breadcrumbs: 1.4rem;
$font-size-faq-widget-all: 1.2rem;
$font-size-home-newsdate: 1.4rem;

$font-size-faq-accordeon-title-text: 2.4rem;
$font-size-text-intro: 2.2rem;

$font-size-accordeon-header: 2rem;
$font-size-text-testimonial-quote: 2rem;
$font-size-news-h3: 1.4rem;

$font-size-situatie-regel-icon: 2.8rem;

$font-size-c2a-home-level1-text: 2.6rem;
$font-size-c2a-home-button-text: 1.8rem;
$font-size-toptaak-tekst: 1.6rem;
$font-size-vragen-headertext: 3.2rem;
$font-size-vragen-maintext: 1.8rem;
$font-size-vragen-showall: 1.2rem;
$font-size-calculator-header: 2.8rem;
$font-size-buttontext: 1.9rem;
$font-size-quote: 3.2rem;

$font-size-mijncak-boodschap-notificatie-titel: 2.2rem;
$font-size-mijncak-boodschap-notificatie-tekst: 1.8rem;

$zakelijk-secondary-color: #007eb5;
$zakelijk-secondary-color-hover: #005ea1;

$zakelijk-vragen-background: #007eb5;

$zakelijk-vragen-button-color: #233983;
$zakelijk-vragen-button-color-hover: #005ea1;

$zakelijk-link-icon-color: #007eb5;

$zakelijk-koptekst-algemeen-color: #005da0;

$zakelijk-colofon-koptekst-algemeen-color: #233983;

$servicemelding-bg-color: #007eb5;

// widths
$mobileViewport: 991px;
$width-tablet-portrait: 768px;
$width-tablet-mobile: 767px;

//overwrites.
$mijncak-light-grey: #cccccc;
$mijncak-light-blue: #ebf5f9;
$mijncak-dark-green: #586f09;

$mijncak-dark-grey: #494848;
$mijncak-lighter-grey: #c1cad4;

$cak-pink: #ea0280;
$cak-red: #ab343e;

@charset "UTF-8";

/*"variables.scss",*/
@import "../node_modules/bootstrap/dist/css/bootstrap.css", "./fonts/font.scss",
  "./sass/variables.scss", "./sass/mixins.scss", "./sass/media-queries.scss",
  "./sass/base.scss", "./sass/header.scss", "./sass/footer.scss",
  "./sass/layout.scss", "./sass/skip-links.scss", "./sass/keyboard-focus.scss",
  "./fonts/icomoon-cak/style.scss", "./sass/cookies.scss",
  "./sass/portalPage.scss", "./sass/formulier-page-form-stepper.scss";
