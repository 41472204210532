$cak-grey: #dadada;

$primary-color: #233983;
$primary-color-sub: #007eb5; // nieuwe kleur: #007EB5, oude kleur te weinig contrast knop: #0095d5
$primary-color-variant: #0066cc;
$primary-color-hover: #005ea1;
$primary-color-mobilesubitem-bg: #1658b6;

$secondary-color: #e70075; // nieuwe kleur: #E70075, oude kleur teweinig contrast knop: #F0007A
$secondary-color-hover: #aa126b;

$secondary-color-darker: #9d0a6d;
$secondary-color-darker-hover: #760952;

$white: #fff;
$black: #000;

$message-top-color: $white;
$message-background-color: $primary-color;

$message-warning-color: #ca0a23;

$generic-text-link-color: #0066cc;

$mijncak-paid: #007500;
$mijncak-notpaid: #ca0a22;

$third-color: #008232;
$cak-light-green: #86bc26;

$keyboard-focus-outline-color: #79bdfa;

$font-family: "MarkPro-Light", sans-serif, arial;
$font-family-bold: "MarkPro-Bold", sans-serif, arial;
$font-family-normal: "MarkPro", sans-serif, arial;

$clickable-elements-sub-color: $secondary-color;
$mijncak-icon-color: $secondary-color;

$faq-bg-color: #ccecf9;

$body-text: #1d1d1d;

$cak-white: #fff;
$cak-black-override: #000;
$cak-grey: #d7d7d7;
$cak-darker-grey: #969696;
$cak-dark-grey: #555555;
$cak-light-grey: #f3fcff;
$cak-lighest-grey: #f2f2f2;
$cak-darkest-blue: #024093;
$cak-light-green: #f0f4e1;
$panel-background-color: $cak-white;
$background-color: #f8f8f8;
$cak-red: #ab343e;
$cak-light-red: #fce9e9;

$breadcrumbs-bg-color: #007eb5;

$block-bg-color: #f2f2f2;
$toptask-border-color: #c9c9c9;

$letop-boodschap-bg-color: #ffecf6;
$letop-boodschap-header-color: #d0021b;

$tip-boodschap-bg-color: #f0f4e1;
$tip-boodschap-header-color: #586f09;

$overlay-bgcolor: #d7d7d7;

$error-color: #ca0a23;
$disabled-color: #d7d7d7;

$font-size-small-text: 1.2rem;
$font-size-maintext: 1.8rem;
$font-size-menutext: 1.8rem;
$font-size-featuretext: 2rem;

$font-size-basetext: 1.8rem;
$font-size-headertext: 3.2rem;

$font-size-home-calc-headertext: 2.8rem;

$font-size-home-level3-text: 2.4rem;

$font-size-head-level1-text: 3.2rem;
$font-size-head-level2-text: 2.8rem;
$font-size-head-level3-text: 2.6rem;

$font-size-faqhead-level2-text: 2.6rem;

$font-size-head-mijncak-block-level2-text: 2rem;

$font-size-breadcrumbs: 1.4rem;
$font-size-faq-widget-all: 1.2rem;
$font-size-home-newsdate: 1.4rem;

$font-size-faq-accordeon-title-text: 2.4rem;
$font-size-text-intro: 2.2rem;

$font-size-accordeon-header: 2rem;
$font-size-text-testimonial-quote: 2rem;
$font-size-news-h3: 1.4rem;

$font-size-situatie-regel-icon: 2.8rem;

$font-size-c2a-home-level1-text: 2.6rem;
$font-size-c2a-home-button-text: 1.8rem;
$font-size-toptaak-tekst: 1.6rem;
$font-size-vragen-headertext: 3.2rem;
$font-size-vragen-maintext: 1.8rem;
$font-size-vragen-showall: 1.2rem;
$font-size-calculator-header: 2.8rem;
$font-size-buttontext: 1.9rem;
$font-size-quote: 3.2rem;

$font-size-mijncak-boodschap-notificatie-titel: 2.2rem;
$font-size-mijncak-boodschap-notificatie-tekst: 1.8rem;

$zakelijk-secondary-color: #007eb5;
$zakelijk-secondary-color-hover: #005ea1;

$zakelijk-vragen-background: #007eb5;

$zakelijk-vragen-button-color: #233983;
$zakelijk-vragen-button-color-hover: #005ea1;

$zakelijk-link-icon-color: #007eb5;

$zakelijk-koptekst-algemeen-color: #005da0;

$zakelijk-colofon-koptekst-algemeen-color: #233983;

$servicemelding-bg-color: #007eb5;

// widths
$mobileViewport: 991px;
$width-tablet-portrait: 768px;
$width-tablet-mobile: 767px;

//overwrites.
$mijncak-light-grey: #cccccc;
$mijncak-light-blue: #ebf5f9;
$mijncak-dark-green: #586f09;

$mijncak-dark-grey: #494848;
$mijncak-lighter-grey: #c1cad4;
