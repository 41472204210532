.grid-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  @media only screen and (max-width: $mobileViewport) {
    flex-direction: column;

    &.row {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &.container-with-top-margin {
    margin-top: 3rem;
  }

  &.grid-container__formulieren {
    padding-top: 3rem;
  }
}

.content-container,
.sidebar {
  display: flex;
  width: 100%;
  flex-basis: 100%;
}

.content-container {
  flex: 2;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: $mobileViewport) {
    margin-right: 0;
    margin-left: 0;
  }

  &.overzicht {
    @media only screen and (max-width: $mobileViewport) {
      flex-direction: column;
    }

    .mijncak-info-block {
      @media only screen and (max-width: $mobileViewport) {
        order: -1;
      }
    }
  }

  h1 {
    color: $primary-color;
    font-family: $font-family-bold;
    font-size: 3.2rem;
    line-height: 1;
    margin-bottom: 3rem;
    padding-left: 1.5rem;
  }

  a {
    text-decoration: underline;
  }

  &.overzicht > div {
    display: flex;
  }
}
.intro-text {
  padding: 0 1.5rem 1.5rem;
}

.sidebar {
  flex: 1;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 40rem;

  @media only screen and (max-width: $mobileViewport) {
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 100%;
  }
}

a {
  color: $generic-text-link-color;
}
