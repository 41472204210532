.home-colofon-container {
  background-color: $primary-color;
  min-height: 8.5rem;
  line-height: 8.5rem;
  color: $white;
  margin-bottom: 0;

  &.navbar {
    padding: 0;

    .navbar-nav {
      display: flex;
      flex-direction: row;

      .nav-item {
        line-height: 8.5rem;

        .nav-link {
          padding: 1.5rem 1.5rem 1.5rem 0;
          margin-right: 4rem;
          text-transform: uppercase;
          font-size: 1.2rem;
          display: inline;
          color: $white;

          @media only screen and (max-width: $mobileViewport) {
            padding-left: 1.5rem;
          }
        }
      }
    }

    .navbar-brand {
      line-height: 2rem;
      @media only screen and (max-width: $mobileViewport) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        line-height: 8.5rem;
      }
    }
  }
}

.cb_button {
  cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link.cb_chatbutton--active {
  color: $cak-light-green;
}

// mobile menu & footermenu mobile
@media only screen and (max-width: $mobileViewport) {
  .navbar-nav {
    width: 100%;
    flex-direction: column !important;

    & > li,
    & > .nav-link {
      border-bottom: 0.1rem solid $primary-color-sub;
    }
  }
}
