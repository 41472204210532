/**
 * @license
 * MyFonts Webfont Build ID 3206075, 2016-04-19T04:30:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Mark Web Pro Light by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/mark/pro-light/
 *
 * Webfont: FF Mark Web Pro Bold by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/mark/pro-bold/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3206075
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: 2013 published by FontShop International GmbH
 *
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/30ebbb");
 */

@font-face {
  font-family: "MarkPro-Light";
  src: url("./fonts/30EBBB_0_0.eot");
  src: url("./fonts/30EBBB_0_0.eot?#iefix") format("embedded-opentype"), url("./fonts/30EBBB_0_0.woff2") format("woff2"),
    url("./fonts/30EBBB_0_0.woff") format("woff"), url("./fonts/30EBBB_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "MarkPro-Bold";
  src: url("./fonts/30EBBB_1_0.eot");
  src: url("./fonts/30EBBB_1_0.eot?#iefix") format("embedded-opentype"), url("./fonts/30EBBB_1_0.woff2") format("woff2"),
    url("./fonts/30EBBB_1_0.woff") format("woff"), url("./fonts/30EBBB_1_0.ttf") format("truetype");
}
