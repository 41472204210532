html,
body {
  height: 100%;
  font-family: $font-family;
  font-size: 1.8rem;
}
html {
  font-size: 62.5%;
}

body {
  padding-top: 16.2rem;
  line-height: 2rem;
  word-wrap: break-word;

  &.openMenu {
    overflow: hidden;
  }
}

.grid-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex: 1 0 auto;
  }

  nav {
    flex-shrink: 0;
  }

  .container-no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.unknown-user-container {
  article.content {
    line-height: 1.3;

    .intro {
      color: $primary-color-sub;
      font-size: 2.2rem;
      margin-bottom: 1rem;
    }

    h2 {
      color: $primary-color;
      font-family: $font-family-bold;
    }
  }
}

.onderhoud-container {
  margin-top: 2rem;
  .onderhoud-melding {
    padding-left: 1.5rem;
  }
}

.cb_online,
.cb_offline,
.cb_accepted {
  display: none;
}
