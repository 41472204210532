.cookies {
  &__container {
    display: block;
    bottom: 0;
    min-width: 100%;
    position: fixed;

    text-align: center;
    z-index: 10001;

    padding: 2rem;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-top: 2px solid #c9c9c9;
    box-shadow: inset 0 1px 1px rgba($black, 0.05);

    line-height: 2.5rem;
    font-size: $font-size-maintext;
    color: #333;
    font-family: $font-family;
  }

  &__title {
    margin: 0;
    padding: 0;
    line-height: 1;
    color: $primary-color;
  }

  &__link {
    text-decoration: underline;
    color: $generic-text-link-color;
    white-space: nowrap;
  }

  &__button {
    background-color: $primary-color-sub;
    border: $primary-color-sub 2px solid;
    font-family: $font-family-bold;
    transition: background-color 0.4s ease 0s, border-color 0.4s ease 0s;
    color: $white;
    font-size: $font-size-maintext;
    border-radius: 0;
    box-shadow: none;
    padding: 0.5rem 1rem;

    line-height: 2.7rem;

    &:hover {
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
      color: $white;
      cursor: pointer;
    }
  }
}
